import React from 'react';
import Typography from '@mui/material/Typography';
import { CustomerDocumentsCell } from '@fingo/lib/components/cells';
import { formatDateTime } from '@fingo/lib/helpers/date';
import UpdateCompanyInformationDialog from '../dialogs/UpdateCompanyInformationDialog';
import FileStatusCell from '../../../components/cells/FileStatusCell';

const baseColumnConfig = {
  sortable: false,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
};

const documentTypesMapper = {
  true: ['client_file_uaf', 'funds_back_up', 'contactability'],
  false: ['client_file_uaf', 'legal_report', 'funds_back_up', 'contactability'],
};

const legalColumns = [
  {
    field: 'client',
    headerName: 'Cliente',
    type: 'string',
    minWidth: 210,
    sortable: true,
    flex: 2,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <UpdateCompanyInformationDialog
        masterEntityId={row.id}
        masterEntityName={row.name}
        isNaturalPersonRut={row.isNaturalPersonRut}
      />
    ),
  },
  {
    ...baseColumnConfig,
    field: 'rut',
    headerName: 'Rut',
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.displayNationalIdentifier}</Typography>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'client-type',
    headerName: 'Tipo de cliente',
    renderCell: ({ row }) => (
      <Typography variant="body2">
        Persona {row.isNaturalPersonRut ? 'Natural' : 'Jurídica'}
      </Typography>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'first-operation',
    headerName: 'Fecha de primera operación',
    renderCell: ({ row }) => (
      <Typography variant="body2">
        {row.metrics?.firstOperationDatetime ? formatDateTime(row.metrics?.firstOperationDatetime) : 'Cliente sin operaciones'}
      </Typography>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'legal-documents',
    headerName: 'Documentos para descargar',
    flex: 2,
    renderCell: ({ row }) => (
      <CustomerDocumentsCell
        masterEntity={row}
        wrap
        initialDocumentTypes={documentTypesMapper[row.isNaturalPersonRut]}
      />
    ),
  },
  {
    ...baseColumnConfig,
    field: 'file-status',
    headerName: 'Estado de la ficha',
    renderCell: ({ row }) => (
      <FileStatusCell
        completedFields={row.legalInformationCompleteFields}
        isNaturalPersonRut={row.isNaturalPersonRut}
      />
    ),
  },
];

export default legalColumns;
