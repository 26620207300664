import React, { useState } from 'react';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { formatGraphQlDatetime } from '@fingo/lib/helpers';
import GET_LEGAL_MASTER_ENTITIES from '../../graphql/queries/getLegalMasterEntities';
import legalColumns from '../../constants/legal-columns';
import LegalInformationActions from '../actions/LegalInformationActions';

const LegalInformation = () => {
  const [masterEntityIds, setMasterEntityIds] = useState([]);
  const country = useGetCountryFromUrl();
  const [states, setStates] = useState({});
  const customQueryDateFilters = {
    ...(states.dateIssued_Gte && {
      metrics_FirstOperationDatetime_Gte: formatGraphQlDatetime(states.dateIssued_Gte),
    }),
    ...(states.dateIssued_Lte && {
      metrics_FirstOperationDatetime_Lte: formatGraphQlDatetime(states.dateIssued_Lte),
    }),
    ...(states.isNaturalPerson !== null && {
      isNaturalPerson: states.isNaturalPerson,
    }),
  };
  return (
    <FingoMainView
      id="legal"
      query={GET_LEGAL_MASTER_ENTITIES}
      queryCustomVariables={{
        countryId: country?.id,
        ...customQueryDateFilters,
      }}
      slots={{
        header: PaperHeader,
        actions: LegalInformationActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Legal',
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        actions: {
          states,
          setStates,
          masterEntityIds,
        },
        table: {
          columns: legalColumns,
          initialOrderBy: 'name',
          noRowsMessage: () => <Typography>No hay clientes</Typography>,
          rowsPerPageOptions: [10, 25, 50, 100],
          checkboxSelection: true,
          selectionModel: masterEntityIds,
          onSelectionModelChange: setMasterEntityIds,
          keepNonExistentRowsSelected: true,
        },
      }}
    />
  );
};
export default LegalInformation;
