import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PersonTypeFilter = ({ states, setStates }) => {
  const [personTypeFilter, setPersonTypeFilter] = useState('all');
  const filterOptions = [
    {
      label: 'Todas las personas',
      value: 'all',
      key: 'all-persons-key',
    },
    {
      label: 'Personas Naturales',
      value: 'natural',
      key: 'physical-persons-key',
    },
    {
      label: 'Personas Jurídicas',
      value: 'juridical',
      key: 'judicial-persons-key',
    },
  ];

  return (
    <FormControl sx={{ m: 1, minWidth: 115 }}>
      <Select
        name="filterType"
        value={personTypeFilter}
        onChange={(e) => {
          setPersonTypeFilter(e.target.value);
          if (e.target.value === 'all') {
            setStates({ ...states, isNaturalPerson: null });
          } else if (e.target.value === 'natural') {
            setStates({ ...states, isNaturalPerson: true });
          } else if (e.target.value === 'juridical') {
            setStates({ ...states, isNaturalPerson: false });
          }
        }}
      >
        {filterOptions.map((filter) => (
          <MenuItem
            value={filter.value}
            key={filter.key}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PersonTypeFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
};

export default PersonTypeFilter;
