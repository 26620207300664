import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { useMutation, useQuery } from '@apollo/client';
import { formatDateTime } from '@fingo/lib/helpers';
import UPLOAD_COMPANY_BENEFICIARIES from '../../graphql/mutations/uploadCompanyBeneficiaries';
import COMPANY_BENEFICIARIES_LAST_UPDATE from '../../graphql/queries/fetchCompanyBeneficiariesLastUpdate';

const UploadCompanyBeneficiariesAction = () => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [open, toggleOpen] = useBooleanState();
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setFiles([{ name: '' }]);
  };
  const [uploadMutation, { loading }] = useMutation(UPLOAD_COMPANY_BENEFICIARIES, {
    variables: { file: files[0] },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'delete-control-group',
      message: err.message,
    }),
    refetchQueries: [COMPANY_BENEFICIARIES_LAST_UPDATE],
  });

  const { data } = useQuery(COMPANY_BENEFICIARIES_LAST_UPDATE);
  const { lastUpdate, user } = data?.fetchCompanyBeneficiariesLastUpdate ?? {};
  return (
    <>
      <Tooltip title={(
        <Typography>
          Cargar beneficiarios con archivo .xlsx de Regcheq<br />
          Fecha última carga: {lastUpdate && formatDateTime(lastUpdate)}<br />
          Usuario: {user?.firstName} {user?.lastName}
        </Typography>
      )}
      >
        <CloudUploadIcon
          color="primary"
          onClick={toggleOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      {open && (
        <FingoDialog
          title="Subir archivo .xslx"
          open={open}
          handleClose={handleClose}
          dialogActionButton={(
            <LoadingButton
              key="upload-bank-statement-button"
              size="small"
              color="primary"
              variant="contained"
              onClick={uploadMutation}
              loading={loading}
              disabled={files[0].name === ''}
            >
              Subir archivo
            </LoadingButton>
        )}
        >
          <UploadDropZoneDialogContainer
            key="upload-bank-statement"
            files={files}
            setFiles={setFiles}
          />
        </FingoDialog>
      )}
    </>
  );
};

export default UploadCompanyBeneficiariesAction;
