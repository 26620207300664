import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import LegalSwitch from '../components/LegalSwitch';

const LegalInformationTabs = (user) => [
  {
    id: 'legal-rosters',
    label: 'Legal',
    path: 'details',
    component: <LegalSwitch />,
    disabled: false,
    show: userHasRoutePermission(user, 'customers.change_masterentity'),
  },
];

export default LegalInformationTabs;
