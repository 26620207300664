import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import FirstOperationDatetimeFilter from './FirstOperationDatetimeFilter';
import PersonTypeFilter from './PersonTypeFilter';
import UploadCompanyBeneficiariesAction from './UploadCompanyBeneficiariesAction';
import CompanyInformationLoadAction from './CompanyInformationLoadAction';
import MassiveInformationDownloadAction from './MassiveInformationDownloadAction';

const LegalInformationActions = ({ states, setStates, masterEntityIds }) => (
  <Stack spacing={1.5} direction="row" alignItems="center">
    <MassiveInformationDownloadAction
      masterEntityIds={masterEntityIds}
    />
    <PersonTypeFilter states={states} setStates={setStates} />
    <FirstOperationDatetimeFilter states={states} setStates={setStates} />
    <UploadCompanyBeneficiariesAction />
    <CompanyInformationLoadAction />
  </Stack>
);

LegalInformationActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
  masterEntityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LegalInformationActions;
