import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { Description as ExcelIcon } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { useLazyQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import DOWNLOAD_MASSIVE_COMPANY_INFORMATION from '../../graphql/queries/downloadMassiveCompanyInformation';

const MassiveInformationDownloadAction = ({ masterEntityIds }) => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [open, toggleOpen] = useBooleanState();
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    if (open) {
      toggleOpen(false);
      setFiles([{ name: '' }]);
    }
  };

  const [downloadInformation, { loading }] = useLazyQuery(DOWNLOAD_MASSIVE_COMPANY_INFORMATION, {
    skip: !masterEntityIds.length || !files[0].name,
    onCompleted: ({ downloadMassiveCompanyInformation: info }) => {
      addAlert({
        id: 'download-massive-information',
        message: `Generando un archivo con ${info.amountOfCompanies} empresas. Se enviará al correo ${info.markedEmail}`,
        color: 'success',
      });
      handleClose();
    },
    onError: (err) => addAlert({
      id: 'delete-control-group',
      message: err.message,
    }),
  });

  const handleClick = () => {
    if (!masterEntityIds.length) {
      toggleOpen();
    } else {
      downloadInformation({ variables: { masterEntityIds } });
    }
  };

  return (
    <>
      <Tooltip title={<Typography>Seleccione las empresas o suba un archivo .xlsx</Typography>}>
        <LoadingButton
          variant="contained"
          size="small"
          startIcon={<ExcelIcon />}
          loading={!open && loading}
          onClick={handleClick}
        >
          Descargar información
        </LoadingButton>
      </Tooltip>
      {open && (
      <FingoDialog
        title="Subir archivo .xlsx"
        open={open}
        handleClose={handleClose}
        dialogActionButton={(
          <LoadingButton
            key="upload-bank-statement-button"
            size="small"
            color="primary"
            variant="contained"
            onClick={() => downloadInformation({ variables: { file: files[0] } })}
            loading={loading}
            disabled={files[0].name === ''}
          >
            Subir archivo
          </LoadingButton>
      )}
      >
        <Stack spacing={1}>
          <Typography>
            Sube un archivo .xlsx con las empresas que deseas descargar la información
            (se debe tener la columna con nombre company_rut)
          </Typography>
          <UploadDropZoneDialogContainer
            key="upload-bank-statement"
            files={files}
            setFiles={setFiles}
          />
        </Stack>
      </FingoDialog>
      )}
    </>
  );
};

MassiveInformationDownloadAction.propTypes = {
  masterEntityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MassiveInformationDownloadAction;
