import { gql } from '@apollo/client';

const COMPANY_BENEFICIARIES_LAST_UPDATE = gql`
query fetchCompanyBeneficiariesLastUpdate {
  fetchCompanyBeneficiariesLastUpdate {
    lastUpdate
    user {
      id
      firstName
      lastName
    }
  }
}
`;

export default COMPANY_BENEFICIARIES_LAST_UPDATE;
