import { gql } from '@apollo/client';

const UPLOAD_COMPANY_INFORMATION = gql`
  mutation uploadCompanyInformation($file: Upload!) {
    uploadCompanyInformation(file: $file) {
      masterEntity {
          id
          compliance {
            id
            fantasyName
          }
        }
      }
    }
`;

export default UPLOAD_COMPANY_INFORMATION;
