import React from 'react';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { hasShowSubRoutes } from '@fingo/lib/helpers/routes';
import { FingoPaper } from '@fingo/lib/components/layout';
import LegalInformationTabs from './routes/LegalInformationTabs';

const legalRoutes = (user) => [
  {
    id: 'legal-download',
    label: 'Información Clientes',
    path: 'legal-client',
    icon: <SendOutlined />,
    component: <FingoPaper tabs={LegalInformationTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, LegalInformationTabs(user)),
  },
];

export default legalRoutes;
