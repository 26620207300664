import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import UPLOAD_COMPANY_INFORMATION from '../../graphql/mutations/uploadCompanyInformation';

const CompanyInformationLoadAction = () => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [open, toggleOpen] = useBooleanState();
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setFiles([{ name: '' }]);
  };
  const [uploadMutation, { loading }] = useMutation(UPLOAD_COMPANY_INFORMATION, {
    variables: { file: files[0] },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'delete-control-group',
      message: err.message,
    }),
  });

  return (
    <>
      <Tooltip title={<Typography>Cargar domicilio y nombre de fantasía</Typography>}>
        <CloudUploadIcon
          color="primary"
          onClick={toggleOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      {open && (
        <FingoDialog
          title="Subir archivo .xslx"
          open={open}
          handleClose={handleClose}
          dialogActionButton={(
            <LoadingButton
              key="upload-bank-statement-button"
              size="small"
              color="primary"
              variant="contained"
              onClick={uploadMutation}
              loading={loading}
              disabled={files[0].name === ''}
            >
              Subir archivo
            </LoadingButton>
        )}
        >
          <Stack spacing={1}>
            <Typography>
              Sube un archivo .xlsx con las empresas que deseas cargar la información.
              Debe tener las siguientes columnas:
              company_rut | fantasy_name
            </Typography>
            <UploadDropZoneDialogContainer
              key="upload-bank-statement"
              files={files}
              setFiles={setFiles}
            />
          </Stack>
        </FingoDialog>
      )}
    </>
  );
};

export default CompanyInformationLoadAction;
