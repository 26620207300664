import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import FilterButton from '@fingo/lib/components/buttons/FilterButton';
import DatesFilter from '@fingo/lib/components/filters/DatesFilter';
import { formatMinWordDate } from '@fingo/lib/helpers/date';
import { TODAY, TWO_MONTHS_AGO } from '@fingo/lib/constants';

const FirstOperationDatetimeFilter = ({ states, setStates }) => {
  const [datesAnchorEl, setDatesAnchorEl] = useState(null);
  const openDateFilter = ({ currentTarget }) => setDatesAnchorEl(currentTarget);
  const [dates, setDates] = useState({
    dateIssued_Gte: TWO_MONTHS_AGO,
    dateIssued_Lte: TODAY,
  });
  const closeDateFilter = () => setDatesAnchorEl(null);
  return (
    <>
      <Tooltip title="Filtrar por fecha de primera operación">
        <FilterButton
          onClick={openDateFilter}
          id="filter-dates"
        >
          <Typography variant="body2">
            {`${formatMinWordDate(
              dates.dateIssued_Gte,
            )} - ${formatMinWordDate(dates.dateIssued_Lte)}`}
          </Typography>
        </FilterButton>
      </Tooltip>
      <DatesFilter
        anchorEl={datesAnchorEl}
        close={closeDateFilter}
        dates={dates}
        setDates={setDates}
        states={states}
        setStates={setStates}
      />
    </>
  );
};

FirstOperationDatetimeFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
};

export default FirstOperationDatetimeFilter;
