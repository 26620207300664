import { gql } from '@apollo/client';

const UPLOAD_COMPANY_BENEFICIARIES = gql`
  mutation uploadCompanyBeneficiaries($file: Upload!) {
    uploadCompanyBeneficiaries(file: $file) {
      beneficiaries {
          id
        }
      }
    }
`;

export default UPLOAD_COMPANY_BENEFICIARIES;
