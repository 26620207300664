import { gql } from '@apollo/client';

const DOWNLOAD_MASSIVE_COMPANY_INFORMATION = gql`
query downloadMassiveCompanyInformation(
  $masterEntityIds: [String]
  $file: Upload
  ) {
    downloadMassiveCompanyInformation(
      masterEntityIds: $masterEntityIds
      file: $file
    ) {
      amountOfCompanies
      markedEmail
    }
  }
`;

export default DOWNLOAD_MASSIVE_COMPANY_INFORMATION;
