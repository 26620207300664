import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { FormikProvider } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@fingo/lib/components/fields/TextField';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import UPDATE_MASTER_ENTITY from '../graphql/mutations/updateMasterEntity';
import GET_LEGAL_MASTER_ENTITY from '../graphql/queries/getLegalMasterEntity';
import GET_LEGAL_MASTER_ENTITIES from '../graphql/queries/getLegalMasterEntities';
import UpdateCompanyInformationFormik from './UpdateCompanyInformationFormik';

const UpdateCompanyInformationDialog = (
  { masterEntityId, masterEntityName, isNaturalPersonRut },
) => {
  const [open, setOpen] = useBooleanState();
  const [isEditing, setIsEditing] = useBooleanState();
  const { formik, labels, updateFormik } = UpdateCompanyInformationFormik(isNaturalPersonRut);
  const [getMasterEntity, { loading }] = useLazyQuery(GET_LEGAL_MASTER_ENTITY, {
    variables: { masterEntityId },
    onCompleted: ({ getMasterEntity: masterEntity }) => {
      updateFormik(formik, masterEntity);
      setOpen(true);
    },
  });
  const [updateMasterEntity, { loading: updateLoading }] = useMutation(UPDATE_MASTER_ENTITY, {
    variables: {
      masterEntityId,
      ...(formik.values.fantasyName) && { fantasyName: formik.values.fantasyName },
      ...(formik.values.phone) && { phone: formik.values.phone },
      ...(formik.values.email) && { email: formik.values.email },
      ...(formik.values.nationality) && { nationality: formik.values.nationality },
      ...(formik.values.profession) && { profession: formik.values.profession },
      ...(formik.values.contractualPurpose) && {
        contractualPurpose: formik.values.contractualPurpose,
      },
      ...(formik.values.fundsOrigin) && { originFunds: formik.values.fundsOrigin },
    },
    onCompleted: () => {
      setIsEditing(false);
    },
    refetchQueries: [GET_LEGAL_MASTER_ENTITIES],
  });
  if (loading) return <CircularProgress size={30} />;
  return (
    <>
      <Typography
        variant="body2"
        fontWeight="bold"
        color="primary"
        onClick={getMasterEntity}
        sx={{ cursor: 'pointer' }}
      >
        {masterEntityName}
      </Typography>
      {open && (
        <FingoDialog
          title="Información cliente"
          open={open}
          maxWidth="xl"
          fullWidth
          handleClose={setOpen}
          sx={{ zIndex: 1100 }}
          dialogActionButton={(
            <LoadingButton
              variant="contained"
              color={isEditing ? 'success' : 'primary'}
              sx={{
                borderRadius: 3,
                paddingX: 3,
              }}
              loading={updateLoading}
              onClick={isEditing ? updateMasterEntity : setIsEditing}
              startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
            >
              {isEditing ? 'Guardar' : 'Editar'}
            </LoadingButton>
          )}
        >
          <FormikProvider value={formik}>
            <form id="update-master-entity-legal-information-form">
              <Grid container spacing={2}>
                {labels.map((label) => (
                  <Grid item xs={label.xs} key={label.name}>
                    <Stack spacing={1}>
                      <Typography variant="h6" fontWeight="bold" color="primary">{label.label}</Typography>
                      <TextField
                        name={label.name}
                        disabled={label.disabled || !isEditing}
                        variant={!isEditing ? 'standard' : label.variant}
                      />
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </form>
          </FormikProvider>
        </FingoDialog>
      )}
    </>
  );
};

UpdateCompanyInformationDialog.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  masterEntityName: PropTypes.string.isRequired,
  isNaturalPersonRut: PropTypes.bool.isRequired,
};

export default UpdateCompanyInformationDialog;
