import { gql } from '@apollo/client';

const GET_LEGAL_MASTER_ENTITY = gql`
query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      rut
      name
      displayNationalIdentifier
      company {
        id
        rut
        address
        mainActivity
        firstLegalRepresentative {
          id
          nationality
          email
          phone
          profession
          contractualPurpose
          originFunds
        }
      }
      address
      economicActivity
      country {
        id
        name
      }
      compliance {
        id
        fantasyName
        beneficiaries {
          id
          masterEntity {
            id
            name
          }
        }
      }
    }
  }
`;

export default GET_LEGAL_MASTER_ENTITY;
