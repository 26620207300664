import { gql } from '@apollo/client';

const UPDATE_MASTER_ENTITY = gql`
  mutation UpdateLegalMasterEntity(
    $masterEntityId: String!,
    $fantasyName: String,
    $phone: String,
    $email: String,
    $nationality: String,
    $profession: String,
    $contractualPurpose: String,
    $originFunds: String,
    ) {
    updateLegalMasterEntity(
      masterEntityId: $masterEntityId,
      fantasyName: $fantasyName,
      phone: $phone,
      email: $email,
      nationality: $nationality,
      profession: $profession,
      contractualPurpose: $contractualPurpose,
      originFunds: $originFunds,
      ) {
      masterEntity {
        id
        rut
        name
        displayNationalIdentifier
        company {
          id
          rut
          address
          mainActivity
          firstLegalRepresentative {
            id
            nationality
            email
            phone
            profession
            contractualPurpose
            originFunds
          }
        }
        address
        economicActivity
        country {
          id
          name
        }
        compliance {
          id
          fantasyName
          beneficiaries {
            id
            masterEntity {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_MASTER_ENTITY;
