import { gql } from '@apollo/client';
import { CompanyDocumentsFields } from '@fingo/lib/graphql/customers/fragment';

const GET_LEGAL_MASTER_ENTITIES = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
    $distinct: Boolean
    $metrics_FirstOperationDatetime_Gte: DateTime
    $metrics_FirstOperationDatetime_Lte: DateTime
    $isNaturalPerson: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
      distinct: $distinct
      metrics_FirstOperationDatetime_Gte: $metrics_FirstOperationDatetime_Gte
      metrics_FirstOperationDatetime_Lte: $metrics_FirstOperationDatetime_Lte
      isNaturalPerson: $isNaturalPerson
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          displayNationalIdentifier
          legalInformationCompleteFields
          isNaturalPersonRut
          metrics {
            id
            firstOperationDatetime
          }
          company {
            id
            name
            documents {
              ...CompanyDocumentsFields
            }
          }
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export default GET_LEGAL_MASTER_ENTITIES;
