import { useFormik } from 'formik';
import * as Yup from 'yup';

const UpdateCompanyInformationFormik = (isNaturalPersonRut) => {
  const naturalPersonFormik = useFormik({
    initialValues: {
      name: '',
      rut: '',
      nationality: '',
      phone: '',
      email: '',
      address: '',
      country: '',
      contractualPurpose: '',
      fundsOrigin: '',
      profession: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Razón Social es requerida'),
      rut: Yup.string().required('Rut es requerido'),
      nationality: Yup.string().required('Nacionalidad es requerida'),
      phone: Yup.string().required('Teléfono es requerido'),
      email: Yup.string().required('Email es requerido'),
      address: Yup.string().required('Dirección es requerida'),
      country: Yup.string().required('País es requerido'),
      contractualPurpose: Yup.string().required('Propósito contractual es requerido'),
      fundsOrigin: Yup.string().required('Origen de fondos es requerido'),
      profession: Yup.string().required('Profesión es requerida'),
    }),
  });
  const naturalPersonLabels = [
    { name: 'name', label: 'Nombre/Razón Social', disabled: true, variant: 'standard', xs: 12 },
    { name: 'rut', label: 'Rut', disabled: true, variant: 'standard', xs: 6 },
    { name: 'nationality', label: 'Nacionalidad', disabled: false, variant: 'filled', xs: 6 },
    { name: 'phone', label: 'Teléfono', disabled: false, variant: 'filled', xs: 6 },
    { name: 'email', label: 'Email', disabled: false, variant: 'filled', xs: 6 },
    { name: 'address', label: 'Dirección', disabled: true, variant: 'standard', xs: 6 },
    { name: 'country', label: 'País', disabled: false, variant: 'filled', xs: 6 },
    { name: 'contractualPurpose', label: 'Propósito de la relación legal o contractual, o de la transacción ocasional', disabled: false, variant: 'filled', xs: 6 },
    { name: 'fundsOrigin', label: 'Origen de fondos', disabled: false, variant: 'filled', xs: 6 },
    { name: 'profession', label: 'Profesión', disabled: false, variant: 'filled', xs: 6 },
  ];
  const updateNaturalPersonFormik = (formik, masterEntity) => {
    const legalRep = masterEntity?.company?.firstLegalRepresentative;
    formik.setFieldValue('name', masterEntity.name);
    formik.setFieldValue('rut', masterEntity.displayNationalIdentifier);
    formik.setFieldValue('nationality', legalRep?.nationality || '');
    formik.setFieldValue('phone', legalRep?.phone ?? '');
    formik.setFieldValue('email', legalRep?.email ?? '');
    formik.setFieldValue('address', masterEntity.company?.address ?? '');
    formik.setFieldValue('country', masterEntity?.country?.name || '');
    formik.setFieldValue('contractualPurpose', legalRep?.contractualPurpose || '');
    formik.setFieldValue('fundsOrigin', legalRep?.originFunds || '');
    formik.setFieldValue('profession', legalRep?.profession ?? '');
  };
  const judicialPersonFormik = useFormik({
    initialValues: {
      name: '',
      rut: '',
      phone: '',
      email: '',
      address: '',
      country: '',
      contractualPurpose: '',
      fundsOrigin: '',
      fantasyName: '',
      mainActivity: '',
      finalBeneficiary: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Razón Social es requerida'),
      rut: Yup.string().required('Rut es requerido'),
      phone: Yup.string().required('Teléfono es requerido'),
      email: Yup.string().required('Email es requerido'),
      address: Yup.string().required('Dirección es requerida'),
      country: Yup.string().required('País es requerido'),
      contractualPurpose: Yup.string().required('Propósito contractual es requerido'),
      fundsOrigin: Yup.string().required('Origen de fondos es requerido'),
      fantasyName: Yup.string().required('Nombre de Fantasía es requerido'),
      mainActivity: Yup.string().required('Giro comercial es requerido'),
      finalBeneficiary: Yup.string().required('Beneficiario final es requerido'),
    }),
  });
  const judicialPersonLabels = [
    { name: 'name', label: 'Nombre/Razón Social', disabled: true, variant: 'standard', xs: 12 },
    { name: 'rut', label: 'Rut', disabled: true, variant: 'standard', xs: 6 },
    { name: 'phone', label: 'Número de teléfono', disabled: false, variant: 'filled', xs: 6 },
    { name: 'email', label: 'Correo', disabled: false, variant: 'filled', xs: 6 },
    { name: 'address', label: 'Domicilio', disabled: true, variant: 'standard', xs: 6 },
    { name: 'country', label: 'País', disabled: true, variant: 'standard', xs: 6 },
    { name: 'contractualPurpose', label: 'Propósito de la relación legal o contractual, o de la transacción ocasional', disabled: false, variant: 'filled', xs: 6 },
    { name: 'fundsOrigin', label: 'Origen de fondos', disabled: false, variant: 'filled', xs: 6 },
    { name: 'fantasyName', label: 'Nombre de Fantasía', disabled: false, variant: 'filled', xs: 6 },
    { name: 'mainActivity', label: 'Giro comercial', disabled: true, variant: 'standard', xs: 6 },
    { name: 'finalBeneficiary', label: 'Beneficiario final', disabled: true, variant: 'filled', xs: 6 },
  ];
  const updateJudicialPersonFormik = (formik, masterEntity) => {
    const legalRep = masterEntity?.company?.firstLegalRepresentative;
    const beneficiaries = masterEntity?.compliance?.beneficiaries ?? [];
    const beneficiariesNames = beneficiaries.map((beneficiary) => beneficiary.masterEntity.name).join(', ');
    formik.setFieldValue('rut', masterEntity.displayNationalIdentifier);
    formik.setFieldValue('name', masterEntity.name);
    formik.setFieldValue('phone', legalRep?.phone ?? '');
    formik.setFieldValue('email', legalRep?.email ?? '');
    formik.setFieldValue('address', masterEntity.company?.address ?? '');
    formik.setFieldValue('country', masterEntity?.country?.name || '');
    formik.setFieldValue('contractualPurpose', legalRep?.contractualPurpose || '');
    formik.setFieldValue('fundsOrigin', legalRep?.originFunds || '');
    formik.setFieldValue('fantasyName', masterEntity?.compliance?.fantasyName ?? '');
    formik.setFieldValue('mainActivity', masterEntity.company?.mainActivity ?? '');
    formik.setFieldValue('finalBeneficiary', beneficiariesNames ?? '');
  };
  if (isNaturalPersonRut) {
    return {
      formik: naturalPersonFormik,
      labels: naturalPersonLabels,
      updateFormik: updateNaturalPersonFormik,
    };
  }
  return {
    formik: judicialPersonFormik,
    labels: judicialPersonLabels,
    updateFormik: updateJudicialPersonFormik,
  };
};

export default UpdateCompanyInformationFormik;
